import { END_POINTS } from "../modules/plugins-common/constants/endpoints";
import { ignite_url } from "../modules/plugins-common/constants/variables";

const createComment = async (requestOptions) => {
  const res = await fetch(
    ignite_url + END_POINTS.ZENDESK_APIS.FULL_CONVERSATION_ZENDESK,
    requestOptions
  )
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.status);
      }
      return res.json();
    })
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return { status: err.message };
    });
  return res;
};

export default createComment;
