const updateDraft = async (ticketId, updateDraftOption) => {
  const client = window.ZAFClient.init();

  const commentId = 123; /// TODO: Where should i get the comment id

  const updatedComment = {
    ticket: {
      comment: {
        body: updateDraftOption.content.body,
        public: true,
      },
    },
  };

  client
    .request({
      url: `${localStorage.getItem(
        "origin"
      )}/api/v2/tickets/${ticketId}/comments/${commentId}.json`,
      type: "PUT",
      data: updatedComment,
    })
    .then((response) => {
      console.log("Comment updated successfully!", response);
    })
    .catch((error) => {
      console.error("Failed to update the comment", error);
    });
};

export default updateDraft;
