const templateObject = {
  ticketId: "",
  ticketType: "",
  currentUserId: "",
  currentUserName: "",
  currentUserEmail: "",
  requesterEmail: "",
};

const getContext = () => {
  const client = window.ZAFClient.init();

  return new Promise((resolve, reject) => {
    client
      .get("ticket.type")
      .then((ticketType) => {
        templateObject.ticketType = ticketType["ticket.type"];

        client
          .get("ticket.id")
          .then((ticketId) => {
            templateObject.ticketId = ticketId["ticket.id"];

            client.get("ticket.requester.email").then(function (data) {
              templateObject.requesterEmail = data["ticket.requester.email"];

              client
                .get("currentUser.id")
                .then((userId) => {
                  templateObject.currentUserId = userId["currentUser.id"];

                  client
                    .get("currentUser.name")
                    .then((userName) => {
                      templateObject.currentUserName =
                        userName["currentUser.name"];

                      client
                        .get("currentUser.email")
                        .then((userEmail) => {
                          templateObject.currentUserEmail =
                            userEmail["currentUser.email"];

                          resolve(templateObject);
                        })
                        .catch((err) => reject(err));
                    })
                    .catch((err) => reject(err));
                })
                .catch((err) => reject(err));
            });
          })
          .catch((err) => reject(err));
      })
      .catch((err) => reject(err));
  });
};

export default getContext;
