import fullConversation from "../../plugin-apis/fullConversation";
import { usePluginContext } from "../../providers/contextManagement";
import usePrimitiveFunctions from "../../modules/plugins-common/services/server/usePrimitiveFunctions";

const useFullMessageThread = (upbrainsToken, teamEmail) => {
  const context =
    usePluginContext() || JSON.parse(localStorage.getItem("context"));
  const { checkAuth, postLog } = usePrimitiveFunctions(
    upbrainsToken,
    teamEmail
  );

  const getFullMessageThread = async (mode, max_token) => {
    try {
      const payload = {
        ticket_id: context?.conversation?.id,
        mode,
        max_token,
      };
      const requestOptions = {
        method: "POST",
        headers: await checkAuth(),
        body: JSON.stringify(payload),
        redirect: "follow",
      };
      const { status, response, response_object } = await fullConversation(
        requestOptions
      );

      if (status !== 200) throw new Error(status);
      return { response, response_object };
    } catch (err) {
      const errorCode = err.message;
      const errorName = err.constructor.name;
      const errorMessage = `Failed to get the full message thread: ${err}`;
      postLog(errorMessage, errorName, errorCode, "ignite-full-conversation");
      return Promise.reject(err);
    }
  };

  return {
    getFullMessageThread,
  };
};

export default useFullMessageThread;
