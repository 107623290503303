export const isZendesk = true;
export const summarizeProduct = "Ticket";
export const responseAssistSource = "Ticket";
export const summarizeSelectedValue = {
  id: "public",
  label: "Public",
  value: "Public",
};
export const listOfComments = [
  {
    id: "public",
    label: "Public",
    value: "Public",
  },
  {
    id: "private",
    label: "Private",
    value: "Private",
  },
  {
    id: "both",
    label: "Public and Private",
    value: "Public and Private",
  },
];
export const messageType = {};

export const conversationTypes = [
  {
    id: isZendesk ? "ticket" : "conversation",
    label: isZendesk ? "This ticket" : "This Conversation",
  },
  {
    id: "all",
    label: isZendesk
      ? "All my tickets with the sender"
      : "All my conversations with the sender",
  },
];

export const conversationCounts = [
  {
    id: 5,
    value: 5,
    label: isZendesk ? "Recent 5 tickets" : "Recent 5 conversations",
  },
  {
    id: 10,
    value: 10,
    label: isZendesk ? "Recent 10 tickets" : "Recent 10 conversations",
  },
  {
    id: 15,
    value: 15,
    label: isZendesk ? "Recent 15 tickets" : "Recent 15 conversations",
  },
];
