import { useEffect, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import getContext from "./providers/getContext";
import { Conversation } from "./modules/plugins-common/pages";
import Setting from "./modules/plugins-common/components/Setting";
import NewLanding from "./modules/plugins-common/pages/NewLanding/NewLanding";

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [rawContext, setRawContext] = useState({});
  const queryParamsList = new URLSearchParams(window.location.search);

  useEffect(() => {
    const today = new Date().getTime();
    if (
      localStorage.getItem("workflowAutoCompleteObj") &&
      JSON.parse(localStorage.getItem("workflowAutoCompleteObj")).expiryDate &&
      today >
        JSON.parse(localStorage.getItem("workflowAutoCompleteObj")).expiryDate
    ) {
      localStorage.removeItem("workflowAutoCompleteObj");
    }
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("isLanguageChecked")) {
      localStorage.setItem("isLanguageChecked", true);
    }
    if (!localStorage.getItem("isGoogleChecked")) {
      localStorage.setItem("isGoogleChecked", true);
    }
  }, []);

  useEffect(() => {
    if (queryParamsList.get("origin")) {
      const domain = queryParamsList.get("origin");
      localStorage.setItem("origin", domain);
    }
    if (queryParamsList.get("app_guid")) {
      localStorage.setItem("app_guid", queryParamsList.get("app_guid"));
    }
  }, []);

  /**
   * First step: getting raw context
   */
  useEffect(() => {
    if (isLoading) {
      getContext()
        .then(function (result) {
          setRawContext(result);
        })
        .catch(function (error) {
          console.error("Error:", error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);

  /**
   * second step:
   * after getting the raw context from ZAFClient
   * start to modify it to use it in the code.
   */
  useEffect(() => {
    if (Object.keys(rawContext)?.length) {
      const modifiedContext = {
        type: rawContext?.ticketType,
        conversation: {
          id: rawContext?.ticketId,
          draftId: rawContext?.ticketId,
          senderEmail: rawContext?.requesterEmail,
        },
        teammate: {
          id: rawContext?.currentUserId,
          name: rawContext?.currentUserName,
          email: rawContext?.currentUserEmail,
          username: rawContext?.currentUserName,
        },
      };

      localStorage.setItem("context", JSON.stringify(modifiedContext));
    }
  }, [rawContext]);

  let StorageChatService = "";
  let isSubscribedToChat = false;
  let emailVerified = false;
  let StorageEmailVerified = "";

  if (localStorage.getItem("ChatService") && !StorageChatService) {
    StorageChatService = JSON.parse(localStorage.getItem("ChatService"));
  }
  if (StorageChatService == "Yes") {
    isSubscribedToChat = true;
  }

  if (localStorage.getItem("EmailVerified") && !StorageEmailVerified) {
    StorageEmailVerified = JSON.parse(localStorage.getItem("EmailVerified"));
  }
  if (StorageEmailVerified == true) {
    emailVerified = true;
  } else {
    emailVerified = false;
    isSubscribedToChat = false;
  }

  let default_tab = "";
  localStorage.getItem("DefaultTab")
    ? (default_tab = localStorage.getItem("DefaultTab"))
    : (default_tab = "Chat");

  return (
    <>
      {!!rawContext?.ticketId && (
        <>
          {emailVerified && default_tab == "Chat" ? (
            <BrowserRouter>
              <Switch>
                <Route exact path="/" component={Conversation} />
                <Route path="/setting/" component={Setting} />
                <Route path="/chat/" component={Conversation} />
              </Switch>
            </BrowserRouter>
          ) : emailVerified && default_tab == "XtractConverse" ? (
            <BrowserRouter>
              <Switch>
                <Route path="/setting/" component={Setting} />
                <Route path="/chat/" component={Conversation} />
              </Switch>
            </BrowserRouter>
          ) : (
            <BrowserRouter>
              <Switch>
                <Route exact path="/" component={NewLanding} />
                <Route path="/setting/" component={Setting} />
                <Route path="/chat/" component={Conversation} />
              </Switch>
            </BrowserRouter>
          )}
        </>
      )}
    </>
  );
};

export default App;
