const listMessages = async () => {
  const client = window.ZAFClient.init();

  return new Promise((resolve, reject) => {
    client
      .request({
        url: `${localStorage.getItem("origin")}/api/v2/tickets/${
          JSON.parse(localStorage.getItem("context"))?.conversation?.id
        }/comments.json`,
        type: "GET",
      })
      .then((response) => {
        const comments = response.comments;

        if (comments && comments.length) {
          const publicComments = comments.filter((comment) => comment.public);

          const modifiedPublicComments = publicComments.map((comment) => {
            if (comment.body) {
              comment.content = {
                body: comment.body,
              };
            }

            if (comment?.via?.source?.from?.name) {
              comment.from = {
                name: comment.via.source.from.name,
              };
            }

            if (comment?.via?.source?.from?.address) {
              comment.from = {
                address: comment.via.source.from.address,
              };
            }

            return comment;
          });
          resolve({ results: modifiedPublicComments });
        }
      })
      .catch((err) => reject(`Failed to fetch list of comments: ${err}`));
  });
};

export default listMessages;
