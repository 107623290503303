const client = window.ZAFClient.init();

const fetchDraft = (ticketId) => {
  client
    .request({
      url: `${localStorage.getItem(
        "origin"
      )}/api/v2/tickets/${ticketId}/comments.json`,
      type: "GET",
    })
    .then((response) => {
      const comments = response.comments;

      const publicComments = comments.filter((comment) => comment.public);

      return { results: publicComments };
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
};

export default fetchDraft;
